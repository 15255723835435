import React, { useCallback, useMemo } from 'react';
import { Divider, Form, Input, Select, Space, Switch } from 'antd';
import styled from 'styled-components';

// Models
import FormItemLabel from 'antd/es/form/FormItemLabel';
import {
  RemoveRestrictedAccessProfilesSettings,
  RemoveRestrictedAccessProfilesSettingsFormValues,
  ScheduleIntervals,
} from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';
import TimeZoneSelect, {
  calculateDayOffset,
  calculateTimeOffset,
} from '../../../../components/ScheduleConfigurations/TimeSelect';
import { getInitialValues, useScheduleConfigurationForm } from '../useScheduleConfigurationForm';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface RemoveRestrictedAccessProfilesSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useRemoveRestrictedAccessProfilesSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: RemoveRestrictedAccessProfilesSettingsProps) => {
  // Form Values
  const initialValues: RemoveRestrictedAccessProfilesSettingsFormValues = useMemo(
    () => ({
      ...getInitialValues(scheduleConfigurationResponse),
      CustomConfiguration: JSON.parse(
        scheduleConfigurationResponse?.CustomConfiguration ?? '{}'
      ) as RemoveRestrictedAccessProfilesSettings,
    }),
    [scheduleConfigurationResponse]
  );

  const scheduleConfigurationData = useScheduleConfigurationForm();

  // Components
  const RemoveRestrictedAccessProfilesSettingsForm = useCallback(
    () => (
      <>
        {scheduleConfigurationData}
        <Divider />
      </>
    ),
    [scheduleConfigurationData]
  );

  return useMemo(
    () => ({
      RemoveRestrictedAccessProfilesSettingsForm,
      initialValues,
    }),
    [RemoveRestrictedAccessProfilesSettingsForm, initialValues]
  );
};
