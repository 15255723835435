import React from 'react';
import { Form, Input, Select, Space, Switch } from 'antd';
import styled from 'styled-components';

// Models
import FormItemLabel from 'antd/es/form/FormItemLabel';
import { ScheduleConfigurationSettingsFormValues, ScheduleIntervals } from '../../../models/ScheduleSettingFormValues';

// Components
import { Translated } from '../../../components/UI/Core';
import TimeZoneSelect, {
  calculateDayOffset,
  calculateTimeOffset,
} from '../../../components/ScheduleConfigurations/TimeSelect';
import { ScheduleConfigurationResponse } from '../../../models/ScheduleConfiguration';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

export const getInitialValues = (
  response: ScheduleConfigurationResponse | null
): ScheduleConfigurationSettingsFormValues => {
  return {
    Id: response?.Id,
    Name: response?.Name,
    Enabled: response?.Enabled,
    Tenant: response?.Tenant,
    Interval: response?.Interval,
    Offset: response?.Offset,
    IntervalCapped: (response?.Interval ?? 0) > 86400 ? 86400 : response?.Interval,
    TimeOffset: response ? calculateTimeOffset(response.Offset) : undefined,
    DayOffset: response ? calculateDayOffset(response.Interval) : undefined,
    ScheduleConfigurationType: response?.ScheduleConfigurationType,
  };
};

// Hook
export const useScheduleConfigurationForm = () => {
  return (
    <>
      {/* Hidden Values */}
      <Item name="Id" hidden>
        <Input />
      </Item>
      <Item name="ScheduleConfigurationType" hidden>
        <Input />
      </Item>
      <Item name="Name" hidden>
        <Input />
      </Item>

      <Form.Item name={['Tenant', 'CompanyId']} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={['Tenant', 'LocationId']} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={['Tenant', 'BusinessClientId']} hidden>
        <Input />
      </Form.Item>

      {/* Hooks that aren't hooks */}
      <Item
        name="Interval"
        dependencies={[['DayOffset'], ['IntervalCapped']]}
        hidden
        rules={[
          ({ getFieldValue, setFieldsValue }) => ({
            validator() {
              const intervalCapped = getFieldValue('IntervalCapped');
              if (intervalCapped < 86400) {
                setFieldsValue({ Interval: intervalCapped });
                return Promise.resolve();
              }

              const dayOffset = getFieldValue('DayOffset');

              const interval = dayOffset * intervalCapped;

              setFieldsValue({ Interval: interval });
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Item>

      {/* Form Values */}
      <StyledSpace align="start">
        <NoSidePaddingFormItem
          name="IntervalCapped"
          label={<Translated id="scheduleConfigurations.form.interval" />}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select placeholder={<Translated id="scheduleConfigurations.form.interval" />}>
            {ScheduleIntervals?.map((interval) => (
              <Option key={interval.Value} value={interval.Value}>
                <Translated id={interval.Translation} />
              </Option>
            ))}
          </Select>
        </NoSidePaddingFormItem>
      </StyledSpace>
      <Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const intervalValue = getFieldValue('IntervalCapped');
          return <TimeZoneSelect intervalValue={intervalValue} />;
        }}
      </Item>
      <Space>
        <FormItemLabel label={<Translated id="scheduleConfigurations.form.enabled" />} prefixCls="cls" />
        <NoMarginBottomFormItem name="Enabled" valuePropName="checked">
          <Switch
            checkedChildren={<Translated id="scheduleConfigurations.form.active" />}
            unCheckedChildren={<Translated id="scheduleConfigurations.form.disabled" />}
          />
        </NoMarginBottomFormItem>
      </Space>
    </>
  );
};
