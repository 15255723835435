import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Input, Modal, Row, Select, Switch } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';
import { usePrevious } from 'react-use';

// Types
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { Tenant } from '../../models/Tenant';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import { createTenant, deleteTenant, deleteTenants, updateTenant } from '../../store/Tenants/Tenants.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { Company, Location } from '../../models';

const { confirm } = Modal;
const { Option } = Select;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface TenantsReduxProps {
  initialValues?: Store | undefined;
  companies?: Company[] | null;
  locations?: Location[] | null;
}

// Hook
export const useTenantsRedux = <T extends object>({ initialValues, companies, locations }: TenantsReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ tenants }) => tenants?.updating ?? false);
  const error = useAppSelector(({ tenants }) => tenants?.error ?? false);

  // Form State
  const [formEditModel, setFormEditModel] = useState(initialValues);
  const prevInitialValues = usePrevious(initialValues);

  // Submit Handling
  const onSubmit = useCallback(
    (tenant: Tenant) => {
      if (formEditModel) {
        dispatch(updateTenant(tenant));
      } else {
        dispatch(createTenant(tenant));
      }
    },
    [dispatch, formEditModel]
  );

  // Form
  const CreateTenantForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as Tenant)}
        initialValues={formEditModel}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="tenants.name" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'tenants.form.warnings.name' }),
                  },
                ]}
              >
                <Input placeholder="Bluefield Smart Access" />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="CompanyId"
                label={<Translated id="tenants.company" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'tenants.form.warnings.company' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="tenants.company" />}>
                  {companies?.map((item) => (
                    <Option key={item.Id} value={item.Id}>
                      {item.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="LocationId"
                label={<Translated id="tenants.location" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'tenants.form.warnings.location' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="tenants.location" />}>
                  {locations?.map((item) => (
                    <Option key={item.Id} value={item.Id}>
                      {item.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <FormItemLabel
                label={<Translated id="tenants.settings.electricParkingReservationsEnabled" />}
                prefixCls="cls"
              />
              <Form.Item name={['Settings', 'ElectricParkingReservationsEnabled']} valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </Form.Item>
            </Col>

            <Col>
              <FormItemLabel
                label={<Translated id="tenants.settings.fastElectricParkingReservationsEnabled" />}
                prefixCls="cls"
              />
              <Form.Item name={['Settings', 'FastElectricParkingReservationsEnabled']} valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </Form.Item>
            </Col>

            <Col>
              <FormItemLabel label={<Translated id="tenants.settings.useCustomEmailTemplates" />} prefixCls="cls" />
              <Form.Item name={['Settings', 'UseCustomEmailTemplates']} valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [companies, locations, onSubmit, updating, formEditModel, intl]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'Tenants',
        Form: CreateTenantForm,
        labels: {
          createButton: <Translated id="tenants.form.create" />,
          drawerForm: <Translated id={formEditModel ? 'tenants.form.edit' : 'tenants.form.create'} />,
          submitButton: <Translated id={formEditModel ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateTenantForm, formEditModel]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (tenant: Tenant | null) => {
      if (tenant) {
        confirm({
          title: intl.formatMessage({
            id: 'tenants.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'tenants.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteTenant(tenant)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const tenants = selectedFlatRows.map((d) => d.original) as Tenant[];
      if (tenants) {
        confirm({
          title: intl.formatMessage({
            id: 'tenants.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'tenants.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteTenants(tenants)),
          onCancel: () => null,
          bodyStyle: { whiteSpace: 'pre-line' },
        });
      }
    },
    [intl, dispatch]
  );

  const showEditForm = useCallback(
    <TRowObject extends object>(selectedRow?: TableRow<TRowObject>) => {
      setFormEditModel(selectedRow);
    },
    [setFormEditModel]
  );

  // Effects
  useEffect(() => {
    // Update after fetching is complete
    if (prevInitialValues !== initialValues) {
      setFormEditModel(initialValues);
    }
  }, [prevInitialValues, initialValues]);

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
      showEditForm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm, showEditForm]
  );
};
