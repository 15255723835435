import React from 'react';
import { Form, Input, InputNumber, Space, TimePicker } from 'antd';
import styled from 'styled-components';
import moment from 'moment'; // Make sure this import matches your data structure.
import { useIntl } from 'react-intl';
import { Translated } from '../UI/Core';

// Components
const { Item } = Form;

interface TimeZoneSelectProps {
  intervalValue: number;
}

const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

export const calculateTimeOffset = (offset: number): moment.Moment => {
  const fullDay = 24 * 3600;

  const utcOffset = new Date().getTimezoneOffset() * 60;
  const timeOffset = (offset + fullDay - utcOffset) % fullDay;

  const hours = Math.floor(timeOffset / 3600);
  const minutes = Math.floor((timeOffset % 3600) / 60);

  return moment(`${hours}:${minutes}`, 'HH:mm');
};

export const calculateDayOffset = (interval: number): number => {
  return Math.max(Math.floor(interval / 86400), 1);
};

const TimeZoneSelect: React.FC<TimeZoneSelectProps> = ({ intervalValue }) => {
  const intl = useIntl();

  if (intervalValue !== 86400) {
    return null;
  }

  return (
    <>
      <Item
        name="Offset"
        dependencies={[['TimeOffset']]}
        hidden
        rules={[
          ({ getFieldValue, setFieldsValue }) => ({
            validator() {
              const fullDay = 24 * 3600;
              const timeOffset = getFieldValue('TimeOffset');

              const utcOffset = new Date().getTimezoneOffset() * 60;

              const offset = (timeOffset.hour() * 3600 + timeOffset.minute() * 60 + utcOffset + fullDay) % fullDay;

              setFieldsValue({ Offset: offset });
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Item>

      <StyledSpace>
        <NoSidePaddingFormItem
          name="DayOffset"
          label={<Translated id="scheduleConfigurations.form.runEvery" />}
          rules={[
            () => ({
              validator(_, value) {
                const num = Number.parseInt(value, 10);
                if (!Number.isNaN(num)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(intl.formatMessage({ id: 'form.warnings.number' })));
              },
            }),
          ]}
        >
          <InputNumber
            type="number"
            pattern="[0-9]*"
            min={1}
            max={30}
            step={1}
            addonAfter={<Translated id="app.days" />}
            style={{ width: '100%' }}
          />
        </NoSidePaddingFormItem>

        <NoSidePaddingFormItem
          label={<Translated id="scheduleConfigurations.form.time" />}
          name="TimeOffset"
          rules={[{ required: false }]}
        >
          <TimePicker style={{ width: '100%' }} format="HH:mm" />
        </NoSidePaddingFormItem>
      </StyledSpace>
    </>
  );
};

export default TimeZoneSelect;
