import React, { useCallback, useMemo } from 'react';
import { Divider, Form, InputNumber, Space } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import {
  CheckOutVisitorsSettings,
  CheckOutVisitorsSettingsSettingsFormValues,
} from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';
import { getInitialValues, useScheduleConfigurationForm } from '../useScheduleConfigurationForm';

// Components
const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface CheckOutVisitorsSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useCheckOutVisitorsSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: CheckOutVisitorsSettingsProps) => {
  // Intl
  const intl = useIntl();

  // Form Values
  const initialValues: CheckOutVisitorsSettingsSettingsFormValues = useMemo(
    () => ({
      ...getInitialValues(scheduleConfigurationResponse),
      CustomConfiguration: JSON.parse(
        scheduleConfigurationResponse?.CustomConfiguration ?? '{}'
      ) as CheckOutVisitorsSettings,
    }),
    [scheduleConfigurationResponse]
  );

  const scheduleConfigurationData = useScheduleConfigurationForm();

  // Components
  const CheckOutVisitorsSettingsForm = useCallback(
    () => (
      <>
        {scheduleConfigurationData}
        <Divider />
        {/* Custom */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['CustomConfiguration', 'Days']}
            label={<Translated id="scheduleConfigurations.form.checkoutAfterDays" />}
            rules={[
              { required: true, message: intl.formatMessage({ id: 'scheduleConfigurations.form.warnings.days' }) },
            ]}
          >
            <InputNumber
              placeholder="Days"
              type="number"
              min={1}
              max={10000}
              defaultValue={7}
              onKeyDown={(e) => {
                if (e.key === '-' || e.key === '.' || e.key.toLowerCase() === 'e') {
                  e.preventDefault();
                }
              }}
            />
          </NoSidePaddingFormItem>
        </StyledSpace>
      </>
    ),
    [intl, scheduleConfigurationData]
  );

  return useMemo(
    () => ({
      CheckOutVisitorsSettingsForm,
      initialValues,
    }),
    [CheckOutVisitorsSettingsForm, initialValues]
  );
};
