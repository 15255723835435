import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Form, Select, Space } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import {
  FetchInvitationEmailsSettings,
  FetchInvitationEmailsSettingsFormValues,
} from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';
import { ApiEndpoints } from '../../../../data/ApiEndpoints';
import { useData } from '../../../App/useData';
import { CalendarEventConfiguration } from '../../../../models/CalendarEventConfiguration';
import { getInitialValues, useScheduleConfigurationForm } from '../useScheduleConfigurationForm';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface FetchInvitationEmailsSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useFetchInvitationEmailsSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: FetchInvitationEmailsSettingsProps) => {
  // Intl
  const intl = useIntl();

  // State
  const [filteredCalendarEventConfigurations, setFilteredCalendarEventConfigurations] = useState<
    CalendarEventConfiguration[] | null
  >(null);

  // Form Values
  const initialValues: FetchInvitationEmailsSettingsFormValues = useMemo(
    () => ({
      ...getInitialValues(scheduleConfigurationResponse),
      CustomConfiguration: JSON.parse(
        scheduleConfigurationResponse?.CustomConfiguration ?? '{}'
      ) as FetchInvitationEmailsSettings,
    }),
    [scheduleConfigurationResponse]
  );

  const {
    data: calendarEventConfigurations,
    fetch,
    pending,
    fetched,
  } = useData(ApiEndpoints.calendarEventConfigurations.list, null);

  // Hooks
  useEffect(() => {
    fetch({ size: 999 });
  }, [fetch]);

  useEffect(() => {
    if (pending || !fetched) {
      return;
    }
    const filtered = calendarEventConfigurations?.filter(
      (x) =>
        x.Tenant.BusinessClientId === scheduleConfigurationResponse?.Tenant.BusinessClientId &&
        x.Tenant.CompanyId === scheduleConfigurationResponse.Tenant.CompanyId &&
        x.Tenant.LocationId === scheduleConfigurationResponse.Tenant.LocationId
    );
    if (filtered !== undefined) setFilteredCalendarEventConfigurations(filtered);
  }, [scheduleConfigurationResponse, calendarEventConfigurations, pending, fetched]);

  const scheduleConfigurationData = useScheduleConfigurationForm();

  // Components
  const FetchInvitationEmailsSettingsForm = useCallback(
    () => (
      <>
        {scheduleConfigurationData}
        <Divider />
        {/* Custom */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['CustomConfiguration', 'ConfigurationId']}
            label={<Translated id="scheduleConfigurations.form.ConfigurationId" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'scheduleConfigurations.form.warnings.ConfigurationId' }),
              },
            ]}
          >
            <Select placeholder={<Translated id="scheduleConfigurations.form.ConfigurationId" />}>
              {filteredCalendarEventConfigurations?.map((configuration) => (
                <Option key={configuration.Id} value={configuration.Id}>
                  {configuration.Name}
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>
      </>
    ),
    [scheduleConfigurationData, intl, filteredCalendarEventConfigurations]
  );

  return useMemo(
    () => ({
      FetchInvitationEmailsSettingsForm,
      initialValues,
    }),
    [FetchInvitationEmailsSettingsForm, initialValues]
  );
};
