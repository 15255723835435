import React, { useCallback, useMemo } from 'react';
import { Divider } from 'antd';

// Models
import { ScheduleSettingsFormValues } from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { getInitialValues, useScheduleConfigurationForm } from '../useScheduleConfigurationForm';

// Props
interface FetchTransactionsSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useFetchTransactionsSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: FetchTransactionsSettingsProps) => {
  // Form Values
  const initialValues: ScheduleSettingsFormValues = useMemo(
    () => ({
      ...getInitialValues(scheduleConfigurationResponse),
      CustomConfiguration: {},
    }),
    [scheduleConfigurationResponse]
  );

  const scheduleConfigurationData = useScheduleConfigurationForm();

  // Components
  const FetchTransactionsSettingsForm = useCallback(
    () => (
      <>
        {scheduleConfigurationData}
        <Divider />
      </>
    ),
    [scheduleConfigurationData]
  );

  return useMemo(
    () => ({
      FetchTransactionsSettingsForm,
      initialValues,
    }),
    [FetchTransactionsSettingsForm, initialValues]
  );
};
