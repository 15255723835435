import React, { useCallback, useMemo } from 'react';
// Models
import { DisableExpiredIdentifiersSettingsFormValues } from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { getInitialValues, useScheduleConfigurationForm } from '../useScheduleConfigurationForm';

// Props
interface DisableExpiredIdentifiersSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useDisableExpiredIdentifiersSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: DisableExpiredIdentifiersSettingsProps) => {
  // Form Values
  const initialValues: DisableExpiredIdentifiersSettingsFormValues = useMemo(
    () => ({
      ...getInitialValues(scheduleConfigurationResponse),
      CustomConfiguration: {},
    }),
    [scheduleConfigurationResponse]
  );

  const scheduleConfigurationData = useScheduleConfigurationForm();

  // Components
  const DisableExpiredIdentifiersSettingsForm = useCallback(() => {
    return scheduleConfigurationData;
  }, [scheduleConfigurationData]);

  return useMemo(
    () => ({
      DisableExpiredIdentifiersSettingsForm,
      initialValues,
    }),
    [DisableExpiredIdentifiersSettingsForm, initialValues]
  );
};
