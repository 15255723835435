import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Form, Select, Space } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import {
  ImportAzureAdUsersSettings,
  ImportAzureAdUsersSettingsFormValues,
} from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';
import { ApiEndpoints } from '../../../../data/ApiEndpoints';
import { useData } from '../../../App/useData';
import { AzureTenant } from '../../../../models/AzureTenant';
import { getInitialValues, useScheduleConfigurationForm } from '../useScheduleConfigurationForm';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface ImportAzureAdSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useImportAzureAdUsersSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: ImportAzureAdSettingsProps) => {
  // Intl
  const intl = useIntl();

  // State
  const [filteredAzureTenants, setFilteredAzureTenants] = useState<AzureTenant[] | null>(null);

  // Form Values
  const initialValues: ImportAzureAdUsersSettingsFormValues = useMemo(
    () => ({
      ...getInitialValues(scheduleConfigurationResponse),
      CustomConfiguration: JSON.parse(
        scheduleConfigurationResponse?.CustomConfiguration ?? '{}'
      ) as ImportAzureAdUsersSettings,
    }),
    [scheduleConfigurationResponse]
  );

  const { data: azureTenants, fetch, pending, fetched } = useData(ApiEndpoints.azureTenants.list, null);

  // Hooks
  useEffect(() => {
    fetch({ size: 999 });
  }, [fetch]);

  useEffect(() => {
    if (pending || !fetched) {
      return;
    }
    const filtered = azureTenants?.filter(
      (x) =>
        x.Tenant.BusinessClientId === scheduleConfigurationResponse?.Tenant.BusinessClientId &&
        x.Tenant.CompanyId === scheduleConfigurationResponse.Tenant.CompanyId &&
        x.Tenant.LocationId === scheduleConfigurationResponse.Tenant.LocationId
    );
    if (filtered !== undefined) setFilteredAzureTenants(filtered);
  }, [scheduleConfigurationResponse, azureTenants, pending, fetched]);

  const scheduleConfigurationData = useScheduleConfigurationForm();

  // Components
  const ImportAzureAdSettingsForm = useCallback(
    () => (
      <>
        {scheduleConfigurationData}
        <Divider />
        {/* Custom */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['CustomConfiguration', 'AzureTenantId']}
            label={<Translated id="scheduleConfigurations.form.AzureTenantId" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'scheduleConfigurations.form.warnings.AzureTenantId' }),
              },
            ]}
          >
            <Select placeholder={<Translated id="scheduleConfigurations.form.AzureTenantId" />}>
              {filteredAzureTenants?.map((tenant) => (
                <Option key={tenant.Id} value={tenant.Id}>
                  {tenant.Name}
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>
      </>
    ),
    [scheduleConfigurationData, intl, filteredAzureTenants]
  );

  return useMemo(
    () => ({
      ImportAzureAdSettingsForm,
      initialValues,
    }),
    [ImportAzureAdSettingsForm, initialValues]
  );
};
