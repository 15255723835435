import moment from 'moment';
import { Tenant } from './Tenant';
import { ScheduleConfigurationJobType } from './enums/ScheduleConfigurationTypes';

export interface ScheduleConfigurationSettingsFormValues {
  Id: string | undefined;
  Name: string | undefined;
  Tenant: Tenant | undefined;
  Enabled: boolean | undefined;
  Interval: number | undefined;
  ScheduleConfigurationType?: ScheduleConfigurationJobType;
  Offset: number | undefined;
  IntervalCapped?: number;
  TimeOffset?: moment.Moment;
  DayOffset?: number;
}

export interface ScheduleSettingsFormValues extends ScheduleConfigurationSettingsFormValues {
  Id: string | undefined;
  Name: string | undefined;
  Tenant: Tenant | undefined;
  Enabled: boolean | undefined;
  Interval: number | undefined;
  ScheduleConfigurationType?: ScheduleConfigurationJobType;
  CustomConfiguration: any;
  Offset: number | undefined;
  OffsetCapped?: number;
  TimeOffset?: moment.Moment;
  DayOffset?: number;
}

export interface ImportAzureAdUsersSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: ImportAzureAdUsersSettings;
}

export interface ImportAzureAdUsersSettings {
  AzureTenantId: string | undefined;
}

export interface CheckOutVisitorsSettingsSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: CheckOutVisitorsSettings;
}

export interface CheckOutVisitorsSettings {
  Days: number | undefined;
}

export interface RemoveRestrictedAccessProfilesSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: RemoveRestrictedAccessProfilesSettings;
}

export interface RemoveRestrictedAccessProfilesSettings {
  NotifyPerson: boolean | undefined;
}

export interface DisableExpiredIdentifiersSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: {};
}

export interface FetchInvitationEmailsSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: FetchInvitationEmailsSettings;
}

export interface FetchInvitationEmailsSettings {
  AzureTenantId: string | undefined;
}

export interface ScheduleInterval {
  Value: number;
  Translation: string;
}

export const ScheduleIntervals: ScheduleInterval[] = [
  {
    Value: 300,
    Translation: 'scheduleConfigurations.form.fiveMinutes',
  },
  {
    Value: 600,
    Translation: 'scheduleConfigurations.form.tenMinutes',
  },
  {
    Value: 900,
    Translation: 'scheduleConfigurations.form.fifteenMinutes',
  },
  {
    Value: 1800,
    Translation: 'scheduleConfigurations.form.halfHour',
  },
  {
    Value: 3600,
    Translation: 'scheduleConfigurations.form.hour',
  },
  {
    Value: 86400,
    Translation: 'scheduleConfigurations.form.custom',
  },
];

export interface TimeInterval {
  Value: number;
  Text: string;
}

export const TimeIntervals: TimeInterval[] = [
  { Text: '00:00', Value: 0 },
  { Text: '01:00', Value: 3600 },
  { Text: '02:00', Value: 2 * 3600 },
  { Text: '03:00', Value: 3 * 3600 },
  { Text: '04:00', Value: 4 * 3600 },
  { Text: '05:00', Value: 5 * 3600 },
  { Text: '06:00', Value: 6 * 3600 },
  { Text: '07:00', Value: 7 * 3600 },
  { Text: '08:00', Value: 8 * 3600 },
  { Text: '09:00', Value: 9 * 3600 },
  { Text: '10:00', Value: 10 * 3600 },
  { Text: '11:00', Value: 11 * 3600 },
  { Text: '12:00', Value: 12 * 3600 },
  { Text: '13:00', Value: 13 * 3600 },
  { Text: '14:00', Value: 14 * 3600 },
  { Text: '15:00', Value: 15 * 3600 },
  { Text: '16:00', Value: 16 * 3600 },
  { Text: '17:00', Value: 17 * 3600 },
  { Text: '18:00', Value: 18 * 3600 },
  { Text: '19:00', Value: 19 * 3600 },
  { Text: '20:00', Value: 20 * 3600 },
  { Text: '21:00', Value: 21 * 3600 },
  { Text: '22:00', Value: 22 * 3600 },
  { Text: '23:00', Value: 23 * 3600 },
  { Text: '24:00', Value: 24 * 3600 },
];
